@import "node_modules/bootstrap/less/bootstrap";

// @import "./spark/spark";
@import "./../../../vendor/laravel/spark/resources/assets/less/spark";

.dataTables_filter {
  float: right;
}

.dataTables_paginate {
  float: right;
}